import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";

type Props = {
    src: string,
    alt: string
};

const MdxImg = ({src, alt} : Props) => {
    const { allImageSharp } = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                node {
                    original {
                        src
                    }
                    fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                    }
                }
                }
            }
        }
    `);
    let image = allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === src
    );
    if(!image)
        return null;
    return (
        <a href={image.node.original.src}
            target="_blank"
            rel="noopener">
            <Img fluid={image.node.fluid} alt={alt} />
        </a>
    );
};

export default MdxImg;