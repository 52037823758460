import React from "react";
import MdxImg from "../MdxImg";
import styles from './Gallery.module.scss';

const Gallery = ({images}) => {
    const halfOrThird = images.length > 2 ? 'third' : 'half';
    return (
        <figure>
            <div>
                {images.map((image,i) => (
                    <div className={styles[`${halfOrThird}`]} key={i}> <MdxImg src={image.src} alt={image.alt} /> </div>
                ))}
            </div>
        </figure>
    );
};

export default Gallery;