import React from "react";
import MdxImg from "../MdxImg";
import styles from './Flexgallery.module.scss';

const Flexgallery = ({images}) => {
    return (
        <figure>
            <div style={{display: "flex"}}>
                {images.map((image,i) => (
                    <div className={styles['picture']} key={i} style={{flex: `${image.flex}`}}>
                        <MdxImg src={image.src} alt={image.alt} />
                    </div>
                ))}
            </div>
        </figure>
    );
};

export default Flexgallery;